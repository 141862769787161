import { capitalize } from "lodash";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Link } from "react-router-dom";
import type { OrderItem } from "@/entities/order-item";
import { OrderItemStatus } from "@/entities/order-item-status";

export function OrderItemPill({ item }: { item: OrderItem }) {
  const colors = {
    [OrderItemStatus.New]: item.hasContext
      ? "bg-amber-100 text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800"
      : "bg-zinc-200 text-zinc-600 hover:bg-zinc-300 hover:text-zinc-700",
    [OrderItemStatus.Setup]: "bg-cyan-100 text-cyan-700 hover:bg-cyan-200 hover:text-cyan-700",
    [OrderItemStatus.Active]: "bg-cyan-100 text-cyan-700 hover:bg-cyan-200 hover:text-cyan-700",
    [OrderItemStatus.Completed]:
      "bg-green-100 text-green-600 hover:bg-green-300 hover:text-green-700",
    [OrderItemStatus.Transferred]:
      "bg-zinc-200 text-zinc-600 hover:bg-zinc-300 hover:text-zinc-700",
    [OrderItemStatus.Cancelled]:
      "opacity-50 bg-zinc-200 text-zinc-600 hover:bg-zinc-300 hover:text-zinc-700",
    [OrderItemStatus.Expired]:
      "bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-700",
  }[item.status];

  return (
    <div>
      <Tooltip.Root delayDuration={0} disableHoverableContent>
        <Tooltip.Trigger asChild>
          <Link
            className={`
              inline-block relative rounded-full text-xs py-0.5 px-2
              font-bold tabular-nums slashed-zero ${colors}
            `}
            to={`/order-items/${item.id}`}
          >
            {item.isDistributed && (
              <svg
                className="absolute left-5 bottom-3.5 w-4 h-4 text-zinc-700/50 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M12.6207 4.55175C13.4205 3.78997 14.6865 3.82082 15.4483 4.62067L20.9483 10.3955C21.664 11.147 21.686 12.3212 20.9988 13.099L15.4988 19.3242C14.7675 20.152 13.5036 20.2302 12.6758 19.4988C11.848 18.7675 11.7698 17.5036 12.5012 16.6758L15.0642 13.7748H8C6.89543 13.7748 6 14.6702 6 15.7748V18C6 19.1046 5.10457 20 4 20C2.89543 20 2 19.1046 2 18V15.7748C2 12.4611 4.68629 9.7748 8 9.7748H14.8332L12.5517 7.37934C11.79 6.57949 11.8208 5.31354 12.6207 4.55175Z"
                />
              </svg>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="fill-current w-4 h-4"
            >
              <path
                d="M3 9.3H21V18.3C21 19.2941 20.1941 20.1 19.2 20.1H4.8C3.80589 20.1 3 19.2941 3 18.3V9.3Z" />
              <path
                d="M21 7.5H13.35H12.9V3H16.5349C17.0817 3 17.5989 3.24856 17.9405 3.67555L21 7.5Z" />
              <path
                d="M2.99999 7.5H10.65H11.1V3H7.46511C6.9183 3 6.40113 3.24856 6.05954 3.67555L2.99999 7.5Z" />
            </svg>
          </Link>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content side="right" sideOffset={4}>
            <div
              className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2">
              <span>
                {item.product?.title ?? <em className="text-zinc-300">Deleted product</em>} -{" "}
                {item.campaignContext?.jobName ? `${item.campaignContext.jobName} - ` : null}
                {item.campaignContext?.jobLocation
                  ? `${item.campaignContext.jobLocation} - `
                  : null}
                {item.isDistributed
                  ? "Sent to " + item.sentTo?.companyName
                  : capitalize(item.status).replace(/_/g, " ")}
              </span>
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </div>
  );
}
