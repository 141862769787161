import { Entity, Type } from "@jobilla/entity";
import { capitalize } from "lodash";
import { snakeCaseToName } from "@/helpers/snake-case-to-name";
import { Invoice } from "@/entities/invoice";
import { OrderItemProductionStatus } from "./order-item-production-status";
import { OrderItemStatusHistory } from "./order-item-status-history";
import { OrderItemsTransferDetails } from "./order-item-transfer-details";
import { Money } from "./money";
import { CampaignContext } from "./campaign-context";
import { Order } from "./order";
import { Product } from "./product";
import { OrderItemStatus, orderItemStatusesAllowedForEditing } from "./order-item-status";

export class OrderItem extends Entity {
  id!: string;
  uuid!: string;
  numericId!: number;
  orderId!: string;
  status: OrderItemStatus = OrderItemStatus.New;
  productId!: string;
  title!: string;
  createdAt!: string;
  isSent?: string;

  @Type(Product)
  product?: Product;

  @Type(Money)
  amount!: Money;

  @Type(() => Order)
  order?: Order;

  @Type(OrderItemStatusHistory)
  public historicStatuses!: OrderItemStatusHistory[];

  @Type(CampaignContext)
  campaignContext!: CampaignContext | null;

  @Type(OrderItemsTransferDetails)
  sentTo!: OrderItemsTransferDetails;

  @Type(OrderItemsTransferDetails)
  receivedFrom!: OrderItemsTransferDetails;

  get isDistributed(): boolean {
    return this.status === OrderItemStatus.Transferred;
  }

  get isDistributedOrCancelled(): boolean {
    return this.status === OrderItemStatus.Cancelled || 
           this.status === OrderItemStatus.Transferred || 
           this.status === OrderItemStatus.Expired;
  }

  get productionStatus(): OrderItemProductionStatus | null {
    if (this.status === "new" && !this.campaignContext) {
      return OrderItemProductionStatus.Available;
    }

    const OrderItemResourceEntries = Object.entries(OrderItemStatus) as [
      keyof typeof OrderItemStatus,
      OrderItemStatus,
    ][];

    const statusKey =
      OrderItemResourceEntries.find(([, value]) => this.status === value)?.[0] ?? null;

    if (!statusKey || statusKey === "Transferred") {
      return null;
    }

    return OrderItemProductionStatus[statusKey];
  }

  get tooltip(): string {
    return `${this.title} - ${capitalize(this.status).replace(/_/g, " ")}`;
  }

  get hasContext() {
    return this.campaignContext && this.campaignContext.id;
  }

  get canBeEdited(): boolean {
    return orderItemStatusesAllowedForEditing.includes(this.status);
  }

  isSplitEnabled(
    hasPermission: boolean,
    invoice: Invoice | undefined,
  ): boolean {
    return hasPermission
      && this.product?.sku === "DH"
      && this.amount.amount >= 100000
      && this.amount.amount % 100 === 0
      && this.canBeEdited
      && (invoice?.canBeEdited || false);
  }

  cannotBeActedOnMessage(action = "Edit"): string {
    const transformedStatuses = orderItemStatusesAllowedForEditing
      .map((status) => snakeCaseToName(status));

    return `${action} is allowed only if Order item has one of following statuses: ` + transformedStatuses.join(", ");
  }
}
